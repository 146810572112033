interface ITierSvg {
  width: number,
  height: number,
  tier: number|null,
}

// Todo: TierSvg width=15 -> width=20 으로 변경하기
function TierSvg({width=50, height=50, tier=null}: ITierSvg) {
  switch (tier) {
    case 0:
      return (
        <svg width={width} height={height} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect width='50' height='50' rx='12' fill='#E09E37'/>
          <path d='M25.0795 40.3977C22.8447 40.3977 20.8608 40.0047 19.1278 39.2188C17.4044 38.4233 16.0455 37.3295 15.0511 35.9375C14.0663 34.5455 13.5644 32.9451 13.5455 31.1364H21.2159C21.2443 32.1402 21.6326 32.9403 22.3807 33.5369C23.1383 34.1335 24.0379 34.4318 25.0795 34.4318C25.8845 34.4318 26.5947 34.2614 27.2102 33.9205C27.8258 33.5701 28.3087 33.0777 28.6591 32.4432C29.0095 31.8087 29.1799 31.0701 29.1705 30.2273C29.1799 29.375 29.0095 28.6364 28.6591 28.0114C28.3087 27.3769 27.8258 26.8892 27.2102 26.5483C26.5947 26.1979 25.8845 26.0227 25.0795 26.0227C24.2462 26.0227 23.4744 26.2263 22.7642 26.6335C22.0634 27.0312 21.5473 27.5852 21.2159 28.2955L14.2841 26.9318L15.4205 10.9091H34.9659V17.2159H21.8977L21.3295 23.6364H21.5C21.9545 22.7557 22.7405 22.0265 23.858 21.4489C24.9754 20.8617 26.2727 20.5682 27.75 20.5682C29.483 20.5682 31.0265 20.9706 32.3807 21.7756C33.7443 22.5805 34.8191 23.6932 35.6051 25.1136C36.4006 26.5246 36.7936 28.1534 36.7841 30C36.7936 32.0265 36.3106 33.8258 35.3352 35.3977C34.3693 36.9602 33.0104 38.1866 31.2585 39.0767C29.5066 39.9574 27.447 40.3977 25.0795 40.3977Z' fill='white'/>
        </svg>
      );
    case 1:
      return (
        <svg width={width} height={height} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect width='50' height='50' rx='12' fill='#B5B5B5'/>
          <path d='M12.1477 35.4545V29.3182L23.8523 10.9091H29.4205V19.0909H26.2386L19.9318 29.0909V29.3182H37.2045V35.4545H12.1477ZM26.2955 40V33.5795L26.4659 30.9091V10.9091H33.8523V40H26.2955Z' fill='white'/>
        </svg>
      );
    case 2:
      return (
        <svg width={width} height={height} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect width='50' height='50' rx='12' fill='#ECC332'/>
          <path d='M24.7955 40.3977C22.5038 40.3977 20.4678 40.0047 18.6875 39.2188C16.9167 38.4233 15.5246 37.3295 14.5114 35.9375C13.4981 34.5455 12.9867 32.9451 12.9773 31.1364H20.9318C20.9413 31.6951 21.1117 32.197 21.4432 32.642C21.7841 33.0777 22.2481 33.4186 22.8352 33.6648C23.4223 33.911 24.0947 34.0341 24.8523 34.0341C25.5814 34.0341 26.2254 33.9062 26.7841 33.6506C27.3428 33.3854 27.7784 33.0208 28.0909 32.5568C28.4034 32.0928 28.5549 31.5625 28.5455 30.9659C28.5549 30.3788 28.375 29.858 28.0057 29.4034C27.6458 28.9489 27.1392 28.5937 26.4858 28.3381C25.8324 28.0824 25.0795 27.9545 24.2273 27.9545H21.2727V22.5H24.2273C25.0133 22.5 25.7045 22.3722 26.3011 22.1165C26.9072 21.8608 27.3759 21.5057 27.7074 21.0511C28.0483 20.5966 28.214 20.0758 28.2045 19.4886C28.214 18.9205 28.0767 18.4186 27.7926 17.983C27.5085 17.5473 27.1108 17.2064 26.5994 16.9602C26.0975 16.714 25.5152 16.5909 24.8523 16.5909C24.1326 16.5909 23.4886 16.7187 22.9205 16.9744C22.3617 17.2301 21.9214 17.5852 21.5994 18.0398C21.2775 18.4943 21.1117 19.0152 21.1023 19.6023H13.5455C13.5549 17.822 14.0426 16.25 15.0085 14.8864C15.9839 13.5227 17.3191 12.4527 19.0142 11.6761C20.7093 10.8996 22.6553 10.5114 24.8523 10.5114C27.0019 10.5114 28.9006 10.8759 30.5483 11.6051C32.2055 12.3343 33.4981 13.3381 34.4261 14.6165C35.3636 15.8854 35.8277 17.339 35.8182 18.9773C35.8371 20.6061 35.2784 21.9413 34.142 22.983C33.0152 24.0246 31.5852 24.6402 29.8523 24.8295V25.0568C32.2008 25.3125 33.9669 26.0322 35.1506 27.2159C36.3343 28.3902 36.9167 29.8674 36.8977 31.6477C36.9072 33.3523 36.3958 34.8627 35.3636 36.179C34.3409 37.4953 32.9157 38.5275 31.0881 39.2756C29.2699 40.0237 27.1723 40.3977 24.7955 40.3977Z' fill='white'/>
        </svg>
      );
    case 3:
      return (
        <svg width={width} height={height} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect width='50' height='50' rx='12' fill='#6DDFA8'/>
          <path d='M13.9318 40V34.3182L24.7841 25.3409C25.5038 24.7443 26.1193 24.1856 26.6307 23.6648C27.1515 23.1345 27.5492 22.59 27.8239 22.0312C28.108 21.4725 28.25 20.8523 28.25 20.1705C28.25 19.4223 28.089 18.7831 27.767 18.2528C27.4545 17.7225 27.0189 17.3153 26.4602 17.0312C25.9015 16.7377 25.2576 16.5909 24.5284 16.5909C23.7992 16.5909 23.1553 16.7377 22.5966 17.0312C22.0473 17.3248 21.6212 17.7557 21.3182 18.3239C21.0152 18.892 20.8636 19.5833 20.8636 20.3977H13.3636C13.3636 18.3523 13.8229 16.5909 14.7415 15.1136C15.66 13.6364 16.9574 12.5 18.6335 11.7045C20.3097 10.9091 22.2746 10.5114 24.5284 10.5114C26.858 10.5114 28.875 10.8854 30.5795 11.6335C32.2936 12.3722 33.6146 13.4138 34.5426 14.7585C35.4801 16.1032 35.9489 17.6799 35.9489 19.4886C35.9489 20.6061 35.7169 21.7187 35.2528 22.8267C34.7888 23.9252 33.9555 25.142 32.7528 26.4773C31.5502 27.8125 29.8409 29.4034 27.625 31.25L24.8977 33.5227V33.6932H36.2614V40H13.9318Z' fill='white'/>
        </svg>
      );
    case 4:
      return (
        <svg width={width} height={height} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect width='50' height='50' rx='12' fill='#50B1F6'/>
          <path d='M30.4432 10.9091V40H22.5455V18.1818H22.375L16.0114 21.9886V15.2841L23.1705 10.9091H30.4432Z' fill='white'/>
        </svg>
      );
    default:
        return (
          <svg width={width} height={height} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='50' height='50' rx='12' fill='#000000'/>
            <path d='M25.0795 40.3977C22.8447 40.3977 20.8608 40.0047 19.1278 39.2188C17.4044 38.4233 16.0455 37.3295 15.0511 35.9375C14.0663 34.5455 13.5644 32.9451 13.5455 31.1364H21.2159C21.2443 32.1402 21.6326 32.9403 22.3807 33.5369C23.1383 34.1335 24.0379 34.4318 25.0795 34.4318C25.8845 34.4318 26.5947 34.2614 27.2102 33.9205C27.8258 33.5701 28.3087 33.0777 28.6591 32.4432C29.0095 31.8087 29.1799 31.0701 29.1705 30.2273C29.1799 29.375 29.0095 28.6364 28.6591 28.0114C28.3087 27.3769 27.8258 26.8892 27.2102 26.5483C26.5947 26.1979 25.8845 26.0227 25.0795 26.0227C24.2462 26.0227 23.4744 26.2263 22.7642 26.6335C22.0634 27.0312 21.5473 27.5852 21.2159 28.2955L14.2841 26.9318L15.4205 10.9091H34.9659V17.2159H21.8977L21.3295 23.6364H21.5C21.9545 22.7557 22.7405 22.0265 23.858 21.4489C24.9754 20.8617 26.2727 20.5682 27.75 20.5682C29.483 20.5682 31.0265 20.9706 32.3807 21.7756C33.7443 22.5805 34.8191 23.6932 35.6051 25.1136C36.4006 26.5246 36.7936 28.1534 36.7841 30C36.7936 32.0265 36.3106 33.8258 35.3352 35.3977C34.3693 36.9602 33.0104 38.1866 31.2585 39.0767C29.5066 39.9574 27.447 40.3977 25.0795 40.3977Z' fill='white'/>
          </svg>
        );
  }
}

export default TierSvg;