import {ISvg} from "@constant/interfaces.ts";

function Bell({width=28, height=29, state=0}: ISvg & {state: number}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 29" fill="none">
      <path d="M14.0545 25.3148C10.9053 25.3148 7.75613 24.8147 4.76916 23.8146C3.63384 23.4226 2.76884 22.6252 2.3904 21.5845C1.99844 20.5438 2.1336 19.3949 2.75532 18.3677L4.30963 15.7862C4.634 15.2456 4.93135 14.1643 4.93135 13.5291V9.6231C4.93135 4.59526 9.02661 0.5 14.0545 0.5C19.0823 0.5 23.1776 4.59526 23.1776 9.6231V13.5291C23.1776 14.1508 23.4749 15.2456 23.7993 15.7997L25.3401 18.3677C25.9213 19.3409 26.0294 20.5167 25.6374 21.5845C25.2455 22.6522 24.394 23.4632 23.3263 23.8146C20.3528 24.8147 17.2036 25.3148 14.0545 25.3148ZM14.0545 2.52736C10.1484 2.52736 6.9587 5.70355 6.9587 9.6231V13.5291C6.9587 14.5158 6.55323 15.9755 6.05315 16.8269L4.49885 19.4084C4.2015 19.9085 4.12041 20.4356 4.29611 20.8817C4.4583 21.3412 4.86377 21.6926 5.41791 21.8818C11.0675 23.774 17.055 23.774 22.7045 21.8818C23.1911 21.7196 23.5695 21.3547 23.7452 20.8681C23.9209 20.3816 23.8804 19.8545 23.6101 19.4084L22.0558 16.8269C21.5422 15.9484 21.1502 14.5022 21.1502 13.5156V9.6231C21.1502 5.70355 17.974 2.52736 14.0545 2.52736Z" fill="#777777"/>
      <path d="M14.0539 28.3965C12.7159 28.3965 11.4184 27.8559 10.4723 26.9098C9.52622 25.9637 8.9856 24.6662 8.9856 23.3281H11.013C11.013 24.1256 11.3373 24.9095 11.9049 25.4771C12.4726 26.0448 13.2565 26.3692 14.0539 26.3692C15.7299 26.3692 17.095 25.0041 17.095 23.3281H19.1223C19.1223 26.1259 16.8517 28.3965 14.0539 28.3965Z" fill="#777777"/>

      { state === 1 ? (
        <circle cx="22" cy="6.5" r="4.25" fill="#66FF88" stroke="white" strokeWidth="1.5"/>
        ) : state == 2 ? (
        <circle cx="22" cy="6.5" r="4.25" fill="#FF6666" stroke="white" strokeWidth="1.5"/>
        ) : null }
    </svg>
  );
}

export default Bell;