import {ISvg} from '@constant/interfaces.ts';

function DeleteIcon({width=28, height=28}: ISvg) {
  return (
    <svg version='1.0' xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 512.000000 512.000000' preserveAspectRatio='xMidYMid meet'>
      <g transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)' fill='#777777' stroke='none'>
        <path d='M2396 5104 c-240 -54 -423 -199 -527 -419 -21 -44 -44 -105 -51 -135 l-14 -55 -545 -5 c-570 -6 -572 -6 -664 -52 -90 -46 -187 -143 -233 -234 -51 -100 -66 -255 -37 -367 45 -172 180 -306 364 -361 49 -14 228 -16 1861 -16 1969 0 1855 -3 1973 56 70 36 187 155 220 226 73 155 73 327 1 471 -36 70 -145 177 -219 214 -122 62 -139 63 -713 63 l-522 0 -6 28 c-32 148 -101 271 -213 382 -94 94 -188 150 -316 190 -94 29 -264 36 -359 14z m296 -264 c80 -24 171 -83 222 -141 35 -41 85 -132 101 -186 l6 -23 -480 0 -480 0 14 40 c52 146 189 273 342 315 75 21 200 18 275 -5z m1650 -600 c58 -17 130 -71 162 -123 80 -130 19 -313 -126 -378 l-53 -24 -1780 0 -1780 0 -50 27 c-64 33 -98 70 -126 133 -46 104 -18 229 69 306 83 73 -54 68 1892 68 1166 1 1767 -3 1792 -9z'/>
        <path d='M820 3183 c-19 -10 -40 -29 -47 -43 -10 -20 -13 -292 -13 -1256 0 -1198 1 -1233 20 -1310 74 -293 315 -515 611 -563 41 -7 451 -11 1164 -11 1196 0 1167 -1 1305 57 177 73 320 207 402 379 78 162 72 48 76 1409 1 671 0 1234 -3 1252 -3 18 -17 46 -31 62 -48 57 -159 49 -201 -15 -17 -27 -18 -88 -23 -1264 -5 -1354 0 -1242 -64 -1368 -33 -65 -116 -149 -188 -189 -117 -66 -47 -63 -1278 -63 -1220 0 -1156 -3 -1275 58 -116 58 -230 213 -254 346 -8 41 -11 440 -11 1249 0 1281 2 1232 -50 1265 -36 22 -102 25 -140 5z'/>
        <path d='M1593 3006 c-17 -8 -40 -27 -50 -42 -17 -27 -18 -82 -21 -1104 -2 -591 0 -1094 3 -1118 17 -119 158 -152 230 -54 20 27 20 43 20 1144 l0 1118 -30 30 c-37 36 -103 48 -152 26z'/>
        <path d='M2483 3004 c-67 -33 -63 34 -63 -1178 0 -1201 -3 -1149 59 -1180 16 -9 48 -16 71 -16 55 0 116 59 125 122 3 24 5 525 3 1113 -3 1018 -4 1072 -21 1099 -34 51 -113 70 -174 40z'/>
        <path d='M3384 3006 c-69 -30 -64 60 -64 -1183 l0 -1123 28 -30 c54 -61 157 -48 202 25 20 32 20 54 20 1130 0 1216 4 1147 -65 1179 -40 19 -79 20 -121 2z'/>
      </g>
    </svg>
  );
}

export default DeleteIcon;