function Camera() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="71" viewBox="0 0 69 71" fill="none">
      <path opacity="0.4" d="M15.9005 70.3877H52.7838C62.4973 70.3877 66.3686 64.44 66.8261 57.19L68.6562 28.1199C69.1489 20.518 63.0956 14.0775 55.4585 14.0775C53.3117 14.0775 51.3408 12.8458 50.3554 10.9453L47.8215 5.84218C46.2025 2.63954 41.9793 0 38.3895 0H30.3301C26.705 0 22.4818 2.63954 20.8629 5.84218L18.3289 10.9453C17.3435 12.8458 15.3726 14.0775 13.2258 14.0775C5.58872 14.0775 -0.464629 20.518 0.0280848 28.1199L1.85817 57.19C2.28049 64.44 6.18701 70.3877 15.9005 70.3877Z" fill="#999999"/>
      <path d="M39.6206 23.7556H29.0624C27.6194 23.7556 26.4229 22.5591 26.4229 21.1161C26.4229 19.6732 27.6194 18.4766 29.0624 18.4766H39.6206C41.0635 18.4766 42.2601 19.6732 42.2601 21.1161C42.2601 22.5591 41.0635 23.7556 39.6206 23.7556Z" fill="#F1F1F1"/>
      <path d="M34.3383 56.7676C40.9082 56.7676 46.2338 51.4417 46.2338 44.8721C46.2338 38.3025 40.9082 32.9766 34.3383 32.9766C27.7686 32.9766 22.4429 38.3025 22.4429 44.8721C22.4429 51.4417 27.7686 56.7676 34.3383 56.7676Z" fill="#F1F1F1"/>
    </svg>
  )
}

export default Camera;