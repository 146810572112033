import {ISvg} from "@constant/interfaces.ts";

function Sharing({width=24, height=24}: ISvg) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M14.7514 24C13.2812 24 11.2006 22.9665 9.55604 18.023L8.65894 15.3334L5.96782 14.4368C1.03409 12.7931 0 10.7136 0 9.24426C0 7.78736 1.03409 5.69542 5.96782 4.03929L16.5455 0.515354C19.1868 -0.368743 21.392 -0.10725 22.75 1.23757C24.108 2.5824 24.3697 4.79887 23.4851 7.4387L19.9592 18.0106C18.3022 22.9665 16.2216 24 14.7514 24ZM6.55339 5.81994C3.08981 6.97798 1.85638 8.34771 1.85638 9.24426C1.85638 10.1408 3.08981 11.5106 6.55339 12.6562L9.69308 13.7021C9.96718 13.7893 10.1914 14.0134 10.2787 14.2874L11.3252 17.4253C12.4714 20.887 13.8544 22.1197 14.7514 22.1197C15.6484 22.1197 17.0189 20.887 18.1776 17.4253L21.7035 6.85346C22.3389 4.93584 22.2267 3.36688 21.4169 2.55749C20.6071 1.74811 19.0373 1.64849 17.1311 2.28355L6.55339 5.81994Z" fill="#777777"/>
      <path d="M9.63104 14.9971C9.39429 14.9971 9.15757 14.9099 8.97069 14.7232C8.60938 14.362 8.60938 13.7643 8.97069 13.4032L13.431 8.93294C13.7923 8.57183 14.3903 8.57183 14.7516 8.93294C15.113 9.29405 15.113 9.89175 14.7516 10.2529L10.2914 14.7232C10.1169 14.9099 9.86775 14.9971 9.63104 14.9971Z" fill="#777777"/>
    </svg>
  )
}

export default Sharing;