import {ISvg} from "@constant/interfaces.ts";

function UserIcon({width=28, height=29}: ISvg) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 29" fill="none">
      <path d="M14.192 16.5227C14.166 16.5227 14.127 16.5227 14.1011 16.5227C14.0621 16.5227 14.0101 16.5227 13.9712 16.5227C11.0223 16.4317 8.81396 14.1324 8.81396 11.3005C8.81396 8.41668 11.1652 6.06543 14.0491 6.06543C16.933 6.06543 19.2842 8.41668 19.2842 11.3005C19.2712 14.1454 17.0499 16.4317 14.231 16.5227C14.205 16.5227 14.205 16.5227 14.192 16.5227ZM14.0361 8.00099C12.2175 8.00099 10.7495 9.48188 10.7495 11.2875C10.7495 13.0672 12.1395 14.5092 13.9062 14.5741C13.9452 14.5611 14.0751 14.5611 14.205 14.5741C15.9457 14.4832 17.3097 13.0542 17.3227 11.2875C17.3227 9.48188 15.8548 8.00099 14.0361 8.00099Z" fill="#777777"/>
      <path d="M14.0364 28.4998C10.542 28.4998 7.20347 27.2008 4.6184 24.8366C4.38457 24.6287 4.28065 24.317 4.30663 24.0182C4.47551 22.4723 5.43679 21.0304 7.0346 19.9652C10.9057 17.3931 17.18 17.3931 21.0381 19.9652C22.636 21.0434 23.5972 22.4723 23.7661 24.0182C23.8051 24.3299 23.6882 24.6287 23.4543 24.8366C20.8693 27.2008 17.5308 28.4998 14.0364 28.4998ZM6.34611 23.7584C8.5025 25.564 11.2175 26.5513 14.0364 26.5513C16.8553 26.5513 19.5702 25.564 21.7266 23.7584C21.4928 22.966 20.8693 22.1995 19.947 21.576C16.7513 19.4456 11.3344 19.4456 8.11279 21.576C7.19048 22.1995 6.57994 22.966 6.34611 23.7584Z" fill="#777777"/>
      <path d="M14.0354 28.4995C6.33213 28.4995 0.0708008 22.2382 0.0708008 14.5349C0.0708008 6.83165 6.33213 0.570312 14.0354 0.570312C21.7386 0.570312 28 6.83165 28 14.5349C28 22.2382 21.7386 28.4995 14.0354 28.4995ZM14.0354 2.51886C7.41033 2.51886 2.01935 7.90984 2.01935 14.5349C2.01935 21.16 7.41033 26.5509 14.0354 26.5509C20.6605 26.5509 26.0514 21.16 26.0514 14.5349C26.0514 7.90984 20.6605 2.51886 14.0354 2.51886Z" fill="#777777"/>
    </svg>
  );
}

export default UserIcon;